body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
  margin-bottom: .5em;
}

.card-header {
  padding-bottom: .3em !important;
  padding-top: .4em !important;
}

.modal-body {
  padding-top: .1em !important;
}

h5 {
  margin-bottom: 0px !important;
}

.close {
  color: #fff; 
  opacity: .6;
}

.close:hover {
  color: rgb(255, 255, 255); 
  opacity: 1;
}

.iconBtn {
  border: 0;
  background: transparent;
}

.highlight {
  background-color: yellow;
}

.is-valid {
  background-image: none !important;
  border-color: rgb(206, 212, 218) !important;
}

.dependentChart{
	background-color: #242424;
}

svg.custom .node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 2px;
}

svg.custom .node text {
	font-size: 16px;
	background-color: #444;
	fill: #F4F4F4;
	text-shadow: 0 1px 4px black;
}

svg.custom .node {
  cursor: pointer;
}

svg.custom path.link {
	fill: none;
	stroke: #2593B8;
	stroke-width: 5px;
}
