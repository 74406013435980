@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap4.css";
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap4.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HeaderNavItem{
  color: white !important;
}

.modal-lg {
  max-width: 70% !important;
}

.ReactTable {
  border: 1px solid rgba(0,0,0,0.3) !important;
}

.ReactTable .rt-tbody .rt-td {
  border: 1px solid rgba(0,0,0,.3) !important;
  border-bottom: 0px solid rgba(0,0,0,.0) !important;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  padding: 4px 3px !important;
  font-size: 10pt;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0,0,0,.3) !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border: 1px solid rgba(0,0,0,.3) !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
border: 1px solid rgba(0,0,0,.3) !important;
}

.e-grid .e-headercell{
  background-color: #007bff;
  color: white;
  font-size: '10px'!important;
}

.e-grid .e-headercelldiv {
  font-size: '10px'!important;
}

.e-grid .e-headercell, .e-grid .e-detailheadercell {
  border-style: solid;
  border-width: 0;
  font-size: 14px;
  font-weight: Bold;
  height: 36px;
  overflow: hidden;
  padding: 0 6px 0!important;
  position: relative;
  text-align: left;
}

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ABFF00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}
.led-green-mini {
  margin-right: 5px;
  margin-top: 5px;
  width: 12px;
  height: 12px;
  background-color: #00cd00;
  border-radius: 50%;
  float: left;
}

.led-red-mini {
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  width: 12px;
  height: 12px;
  background-color: #F00;
  border-radius: 50%;
}


.cloudHeader{
  background-color: #7ec0ee;
}

.headerShadow{
  text-shadow: 2px 2px #000000;
}

.gitToolTip{
  padding-left: 5px;
  padding-right:5px;
  border-width:1px;
  background-color: #f0f0f0;
   border-color: 'black';
    border-radius: 5px; 
    z-index: 999;
    box-shadow: 3px 2px 2px grey;

}

.recharts-tooltip-wrapper {
  z-index: 100;
}

.ruleGroup {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: rgba(186, 186, 199, 0.5); }
  .ruleGroup .rule,
  .ruleGroup .ruleGroup {
    margin-top: 0.5rem;
    margin-left: 0.5rem; }
  .ruleGroup .ruleGroup-combinators.betweenRules {
    margin-top: 0.5rem; }
  .ruleGroup .ruleGroup-notToggle {
    margin-right: 0.5rem; }

.rule-fields  {
    padding: .375rem .75rem;
    font-size: 1rem;
    margin-right: 1em;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.rule-value  {
  padding: .375rem .75rem;
  font-size: 1rem;
  margin-right: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.rule-operators {
    padding: .375rem .75rem;
    font-size: 1rem;
    margin-right: 1em;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.ruleGroup-combinators {
  padding: .375rem .75rem;
  margin-right: 1em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.ruleGroup-addRule{
  margin-right: 1em;
}

.ruleGroup-addGroup{
  margin-right: 1em;
}

.dashedBorder{
  border-style: dashed;
  border-color: #4950575b;
  padding: 5px;
}

.commentResponse {
  border-left: 1px solid rgba(0,0,0,.1);
  margin-left: 15px;
  padding-left: 10px;
}

.commentResponse2 {
  border-left: 1px solid rgba(0,0,0,.1);
  margin-left: 30px;
  padding-left: 10px;
}

.theader{
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
}
